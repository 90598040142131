.links {
	justify-content: space-between;
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
}

.link {
	display: flex;
}
