.root {
	--background-color: var(--token-color-surface-faint);

	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: var(--hdsplus-spacing-03);
	background-color: var(--background-color);
	display: grid;
	grid-template-rows: minmax(0, 1fr) max-content;
}

.image {
	flex-grow: 1;

	& img {
		width: 100%;
		height: 100%;
		object-fit: var(--object-fit);
		object-position: var(--object-position);
	}
}

.contentWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 var(--hdsplus-spacing-07) var(--hdsplus-spacing-08)
		var(--hdsplus-spacing-07);
	gap: var(--hdsplus-spacing-05);

	/* HACK: Allows overriding the text color with
		 'light mode' var(--token-color-foreground-strong)
		 when the item's textColor prop is set to 'inverted' */
	&[data-theme='light'] {
		--token-color-foreground-strong: #0c0c0e;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	gap: var(--hdsplus-spacing-01);
}

.heading {
	font-weight:  var(--font-weight-medium);

	@media (--small) {
		font-family: var(--hdsplus-typography-body-200-font-family);
		font-size: var(--hdsplus-typography-body-200-font-size);
		line-height: var(--hdsplus-typography-body-200-line-height);
		letter-spacing: var(
			--hdsplus-typography-body-200-letter-spacing
		);
	}
}

.arrow {
	align-self: flex-end;
	flex-shrink: 0;
	color: var(--token-color-foreground-strong);
}

.link {
	position: absolute;
	inset: 0;
	outline: none;
}
